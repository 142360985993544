import React, { useState } from 'react';
import { Grid, TextField, Paper } from '@material-ui/core';
import { serverConfig } from '../../config';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import SimpleSnackbar from '../SimpleSnackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

export default function ConfessionMiddle() {
  const [loading, setLoading] = useState(false);
  const [articleUrlFlag, setArticleUrlFlag] = useState(true);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [inputStr1, setInputStr1] = useState('');
  const [inputStr2, setInputStr2] = useState('');
  const [result, setResult] = useState('');

  const [warningMessage, setWarningMessage] = useState('init');
  const [severity, setSeverity] = useState('info');

  const isEmpty = (code) => {
    if (code == '') return false;
    return true;
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const getMiddle = (e) => {
    e.preventDefault();

    // Props of SimpleSnackbar
    var tempWarningMessage = '';
    var tempSeverity = '';
    var tempLoading = true;
    var emptyFlag = isEmpty(inputStr1 + inputStr2);
    var tempResult = '';

    setLoading(true);

    if (!emptyFlag) {
      tempWarningMessage = '所填内容不能为空';
      tempSeverity = 'error';
      setWarningMessage(tempWarningMessage);
      setSeverity(tempSeverity);
      setLoading(false);
      setSnackBarOpen(true);
    } else {
      if (articleUrlFlag) {
        let postUrl = new URL('/api/confession', serverConfig.baseUrl);
        let formData = new FormData();

        formData.append('size', 'middle');
        formData.append('input_str1', inputStr1);
        formData.append('input_str2', 'inputStr2');

        axios({
          method: 'POST',
          url: postUrl,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => response.data)
          .then((data) => {
            tempWarningMessage = data['warningMessage'];
            tempSeverity = data['severity'];
            tempLoading = false;
            tempResult = data['output_str'];
          })
          .catch(function (e) {
            console.log('error: ', e);
            tempWarningMessage = '系统错误';
            tempSeverity = 'error';
            tempLoading = false;
          })
          .finally(() => {
            if (!emptyFlag) loading = false;
            setWarningMessage(tempWarningMessage);
            setSeverity(tempSeverity);
            setLoading(tempLoading);
            setSnackBarOpen(true);
            setResult(tempResult);
          });
      }
    }
  };

  const [copied, setCopied] = useState(false);

  return (
    <Grid>
      <form onSubmit={getMiddle}>
        <br />
        _____，这几个字太缱绻了
        <div>
          <TextField
            type="input1"
            id="inputContent1"
            name="inputContent1"
            label="请输入要表白的对象"
            placeholder=""
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={inputStr1}
            onChange={(e) => setInputStr1(e.target.value)}
          />
        </div>
        <Grid item>
          <div>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              提交
            </Button>
            {loading && <CircularProgress size={24} />}
          </div>
        </Grid>
      </form>

      <Grid item>
        {result ? (
          <Grid>
            <br />
            <Grid>
              <Paper>
                <Highlight>{result}</Highlight>
              </Paper>
            </Grid>
            <br />
            <CopyToClipboard text={result} onCopy={() => setCopied(true)}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  setSnackBarOpen(true);
                  setWarningMessage('复制成功');
                  setSeverity('success');
                }}
              >
                复制到剪切板
              </Button>
            </CopyToClipboard>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>

      <SimpleSnackbar
        snackBarOpen={snackBarOpen}
        warningMessage={warningMessage}
        severity={severity}
        handleSnackBarClose={handleSnackBarClose}
      />
    </Grid>
  );
}
