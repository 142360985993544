import React, { useEffect, useCallback, makeStyles, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { serverConfig } from '../../config';
import { Grid, Paper } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import axios from 'axios';
import SimpleSnackbar from '../SimpleSnackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const SquarePaper = (props) => <Paper square {...props} />;

const Coedna = observer(() => {
  const [inputStr, setInputStr] = useState('');
  const [outputStr, setOutputStr] = useState('');

  const [warningMessage, setWarningMessage] = useState('init');
  const [severity, setSeverity] = useState('info');
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [copied, setCopied] = useState(false);
  const [typeId, setTypeId] = useState('0');
  const [suffixId, setSuffixId] = useState('0');

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  useEffect(() => {
    let initUrl = new URL('/api/coedna', serverConfig.baseUrl);
    axios({
      method: 'GET',
      url: initUrl,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }, []);

  const handleChangeTypeId = (e) => {
    setTypeId(e.target.value);
  };

  const handleChangeSuffixId = (e) => {
    setSuffixId(e.target.value);
  };

  const submit = (e) => {
    e.preventDefault();

    let url = new URL('/api/coedna', serverConfig.baseUrl);

    let formData = new FormData();
    formData.append('input_str', inputStr);
    formData.append('type_id', typeId);
    formData.append('suffix_id', suffixId);

    var warningMessage;
    var severity;

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.data == 'Success') {
          setOutputStr(data.output_str);
        }
        warningMessage = data.message;
        severity = data.severity;
      })
      .catch((e) => {
        console.log('error: ', e);
      })
      .finally(() => {
        setWarningMessage(warningMessage);
        setSeverity(severity);
        setSnackBarOpen(true);
      });
  };

  return (
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <form onSubmit={submit}>
          <TextField
            type="input"
            id="input_str"
            name="input_str"
            label="请输入要转换的文本"
            placeholder="abc"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={inputStr}
            onChange={(e) => {
              setInputStr(e.target.value);
            }}
          />
          <Grid item>
            <Grid container direction="row" spacing={5}>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">样式</FormLabel>
                  <RadioGroup
                    aria-label="typeId"
                    name="typeId"
                    value={typeId}
                    onChange={handleChangeTypeId}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="ABC" />
                    <FormControlLabel value="1" control={<Radio />} label="𝔸𝔹ℂ" />
                    <FormControlLabel value="2" control={<Radio />} label="𝒜𝐵𝒞" />
                    <FormControlLabel value="3" control={<Radio />} label="𝘼𝘽𝘾" />
                    <FormControlLabel value="4" control={<Radio />} label="ᴬᴮᶜ" />
                    <FormControlLabel value="5" control={<Radio />} label="ₐBC" />
                    <FormControlLabel value="6" control={<Radio />} label="A҉B҉C҉" />
                    <FormControlLabel value="7" control={<Radio />} label="A͛⦚B͛⦚C͛" />
                    <FormControlLabel value="8" control={<Radio />} label="A♥B♥C" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">前后缀</FormLabel>
                  <RadioGroup
                    aria-label="suffixId"
                    name="suffixId"
                    value={suffixId}
                    onChange={handleChangeSuffixId}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="无" />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="`✵•.¸,✵°✵.｡.✰ ______ ✰.｡.✵°✵,¸.•✵´"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="¸.·✩·.¸¸.·¯⍣✩ ______ ✩⍣¯·.¸¸.·✩·.¸"
                    />
                    <FormControlLabel value="3" control={<Radio />} label="╰•★★ ______ ★★•╯" />
                    <FormControlLabel value="4" control={<Radio />} label="ミ★ ______ ★彡" />
                    <FormControlLabel
                      value="5"
                      control={<Radio />}
                      label="¨˜ˆ”°⍣~•✡⊹٭„¸ ______ ¸„٭⊹✡•~⍣°”ˆ˜¨"
                    />
                    <FormControlLabel value="6" control={<Radio />} label="෴❤️෴ ______ ෴❤️෴" />
                    <FormControlLabel
                      value="7"
                      control={<Radio />}
                      label="(◍•ᴗ•) ミ💖 ______ 💖彡"
                    />
                    <FormControlLabel
                      value="8"
                      control={<Radio />}
                      label="💖´ *•.¸♥¸.•**______ **•.¸♥¸.•*´💖"
                    />
                    <FormControlLabel
                      value="9"
                      control={<Radio />}
                      label="♡〜ლ(๑癶 ______ 癶๑)ლ〜♡"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <label htmlFor="contained-button-file">
              <div>
                <Button variant="contained" color="primary" type="submit">
                  提交
                </Button>
              </div>
            </label>
          </Grid>
        </form>
      </Grid>
      <Grid item>
        {outputStr ? (
          <Grid>
            <br />
            <div>{outputStr}</div>
            <br />
            <CopyToClipboard text={outputStr} onCopy={() => setCopied(true)}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  setSnackBarOpen(true);
                  setWarningMessage('复制成功');
                  setSeverity('success');
                }}
              >
                复制到剪切板
              </Button>
            </CopyToClipboard>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <SimpleSnackbar
        snackBarOpen={snackBarOpen}
        warningMessage={warningMessage}
        severity={severity}
        handleSnackBarClose={handleSnackBarClose}
      />
    </Grid>
  );
});

export default Coedna;
