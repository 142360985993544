import React, { useEffect, useCallback, makeStyles, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { serverConfig } from '../../config';
import { Grid, Paper } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import axios from 'axios';
import SimpleSnackbar from '../SimpleSnackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

const SquarePaper = (props) => <Paper square {...props} />;

const Weibo = observer(() => {
  const [inputStr, setInputStr] = useState('');
  const [outputStr, setOutputStr] = useState('');
  const [ID, setID] = useState('');

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('init');
  const [severity, setSeverity] = useState('info');
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [copied, setCopied] = useState(false);

  const isEmpty = (code) => {
    if (code == '') return false;
    return true;
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  useEffect(() => {
    let initUrl = new URL('/api/weibo', serverConfig.baseUrl);
    axios({
      method: 'GET',
      url: initUrl,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }, []);

  const submit = (e) => {
    e.preventDefault();

    let url = new URL('/api/weibo', serverConfig.baseUrl);

    let formData = new FormData();
    formData.append('input_str', inputStr);

    var tempWarningMessage = '';
    var tempSeverity = '';
    var tempLoading = true;
    var emptyFlag = isEmpty(code + ID);
    var tempID;

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.data == 'Success') {
          setOutputStr(data['ID']);
        }
        tempWarningMessage = data['message'];
        tempSeverity = data['severity'];
        tempLoading = false;
        tempID = data['ID'];
      })
      .catch((e) => {
        console.log('error: ', e);
        tempWarningMessage = '系统错误';
        tempSeverity = 'error';
        tempLoading = false;
        tempID = '';
      })
      .finally(() => {
        if (!emptyFlag) loading = false;
        setWarningMessage(tempWarningMessage);
        setSeverity(tempSeverity);
        setLoading(tempLoading);
        setSnackBarOpen(true);
        setID(tempID);
      });
  };

  return (
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <form onSubmit={submit}>
          <br />
          <TextField
            type="input"
            id="input_str"
            name="input_str"
            label="请输入要转换的文本"
            placeholder="在干嘛呢"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={inputStr}
            onChange={(e) => {
              setInputStr(e.target.value);
            }}
          />
          <Grid>
            <label htmlFor="contained-button-file">
              <div>
                <Button variant="contained" color="primary" type="submit">
                  提交
                </Button>
              </div>
            </label>
          </Grid>
        </form>
      </Grid>
      <Grid item>
        {outputStr ? (
          <Grid>
            <br />
            <Paper>
              <Highlight>{outputStr}</Highlight>
            </Paper>
            <br />
            <CopyToClipboard text={outputStr} onCopy={() => setCopied(true)}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  setSnackBarOpen(true);
                  setWarningMessage('复制成功');
                  setSeverity('success');
                }}
              >
                复制到剪切板
              </Button>
            </CopyToClipboard>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <SimpleSnackbar
        snackBarOpen={snackBarOpen}
        warningMessage={warningMessage}
        severity={severity}
        handleSnackBarClose={handleSnackBarClose}
      />
    </Grid>
  );
});

export default Weibo;
