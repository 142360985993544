import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddsshTool from './AddsshTool.jsx';
import ImageTool from './ImageTool.jsx';
import MarkdownTool from './MarkdownTool.jsx';

export default function Utils() {
  const [articleUrlFlag, setArticleUrlFlag] = useState(1);

  const [code, setCode] = useState('');
  const [ID, setID] = useState('');

  return (
    <Grid>
      <Grid>
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button
            onClick={() => {
              setArticleUrlFlag(1);
              setCode('');
              setID('');
              console.log(articleUrlFlag);
            }}
            color={articleUrlFlag == 1 ? 'primary' : 'black'}
          >
            图片显示
          </Button>{' '}
          <Button
            onClick={() => {
              setArticleUrlFlag(2);
              setCode('');
              setID('');
              console.log(articleUrlFlag);
            }}
            color={articleUrlFlag == 2 ? 'primary' : 'black'}
          >
            Markdown 解析
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(3);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 3 ? 'primary' : 'black'}
          >
            添加 ssh-key
          </Button>
        </ButtonGroup>
      </Grid>
      <br />

      {articleUrlFlag == 1 ? (
        <ImageTool />
      ) : articleUrlFlag == 2 ? (
        <MarkdownTool />
      ) : (
        <AddsshTool />
      )}
    </Grid>
  );
}
