import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Coedna from './Coedna.jsx';
import ConfessionLong from './ConfessionLong.jsx';
import ConfessionMiddle from './ConfessionMiddle.jsx';
import ConfessionShort from './ConfessionShort.jsx';
import ConstellationLong from './ConstellationLong.jsx';
import ConstellationShort from './ConstellationShort.jsx';
import MaddogLong from './MaddogLong.jsx';
import MaddogShort from './MaddogShort.jsx';
import Beijinghua from './Beijinghua.jsx';
import Chaohua from './Chaohua.jsx';
import Weibo from './Weibo.jsx';

export default function Features() {
  const [articleUrlFlag, setArticleUrlFlag] = useState(2);

  const [code, setCode] = useState('');
  const [ID, setID] = useState('');

  return (
    <Grid>
      <Grid>
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button
            onClick={() => {
              setArticleUrlFlag(2);
              setCode('');
              setID('');
              console.log(articleUrlFlag);
            }}
            color={articleUrlFlag == 2 ? 'primary' : 'black'}
          >
            模板 1
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(3);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 3 ? 'primary' : 'black'}
          >
            模板 2
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(4);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 4 ? 'primary' : 'black'}
          >
            模板 3
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(5);
              setCode('');
              setID('');
              console.log(articleUrlFlag);
            }}
            color={articleUrlFlag == 5 ? 'primary' : 'black'}
          >
            模板 4
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(6);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 6 ? 'primary' : 'black'}
          >
            模板 5
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(7);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 7 ? 'primary' : 'black'}
          >
            模板 6
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(8);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 8 ? 'primary' : 'black'}
          >
            模板 7
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(9);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 9 ? 'primary' : 'black'}
          >
            模板 8
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(10);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 10 ? 'primary' : 'black'}
          >
            模板 9
          </Button>
          <Button
            onClick={() => {
              setArticleUrlFlag(11);
              setCode('');
              setID('');
            }}
            color={articleUrlFlag == 11 ? 'primary' : 'black'}
          >
            模板 10
          </Button>
        </ButtonGroup>
      </Grid>

      {articleUrlFlag == 2 ? (
        <ConfessionLong />
      ) : articleUrlFlag == 3 ? (
        <ConfessionMiddle />
      ) : articleUrlFlag == 4 ? (
        <ConfessionShort />
      ) : articleUrlFlag == 5 ? (
        <ConstellationLong />
      ) : articleUrlFlag == 6 ? (
        <ConstellationShort />
      ) : articleUrlFlag == 7 ? (
        <MaddogLong />
      ) : articleUrlFlag == 8 ? (
        <MaddogShort />
      ) : articleUrlFlag == 9 ? (
        <Chaohua />
      ) : articleUrlFlag == 10 ? (
        <Coedna />
      ) : (
        <Weibo />
      )}
    </Grid>
  );
}
