import React, { useState } from 'react';
import { serverConfig } from '../../config';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import SimpleSnackbar from '../SimpleSnackbar';

export default function AddsshDialog(props) {
  const { inputStr } = props;
  const [warningMessage, setWarningMessage] = useState('init');
  const [severity, setSeverity] = useState('info');
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [formDialogOpen, setFormDialogOpen] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const handleFormDialogOpenClose = () => {
    setFormDialogOpen(false);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const verify = (e) => {
    if (password === '123') {
      setPasswordError(false);
      setFormDialogOpen(false);
      e.preventDefault();
      console.log(e);

      let formData = new FormData();
      formData.append('input_str', inputStr);
      console.log(formData);
      let url = new URL('/api/add_ssh', serverConfig.baseUrl);
      var warningMessage, severity;
      axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(function (response) {
          const status_code = parseInt(response.data.status_code);
          console.log(status_code);
          switch (status_code) {
            case 200:
              warningMessage = '上传成功';
              severity = 'success';
              break;
            default:
              warningMessage = '系统错误';
              severity = 'error';
          }
        })
        .catch((e) => {
          console.log('error: ', e);
          warningMessage = '系统错误';
          severity = 'error';
        })
        .finally(() => {
          setWarningMessage(warningMessage);
          setSeverity(severity);
          setSnackBarOpen(true);
          if (severity == 'success') location.reload();
        });
    } else {
      setPasswordError(true);
    }
  };

  return (
    <Grid>
      <Dialog
        open={formDialogOpen}
        onClose={handleFormDialogOpenClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">请输入密码</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            error={passwordError}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            helperText={passwordError ? '密码错误，请重试。' : ''}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormDialogOpenClose} color="primary">
            Cancel
          </Button>
          <Button onClick={verify} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <SimpleSnackbar
        snackBarOpen={snackBarOpen}
        warningMessage={warningMessage}
        severity={severity}
        handleSnackBarClose={handleSnackBarClose}
      />
    </Grid>
  );
}
