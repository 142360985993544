import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import AddsshDialog from './AddsshDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  })
);

export default function AddsshTool() {
  const classes = useStyles();
  const [inputStr, setInputStr] = useState('');
  const [formDialogOpen, setFormDialogOpen] = useState(false);

  return (
    <Grid>
      <Grid
        spaceing={2}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <TextField
            type="input"
            id="input_str"
            name="input_str"
            label="请输入 id_rsa.pub 的内容"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={inputStr}
            onChange={(e) => {
              setInputStr(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            id="btnSave"
            onClick={() => {
              setFormDialogOpen(true);
            }}
          >
            提交
          </Button>
        </Grid>
      </Grid>

      {formDialogOpen ? (
        <AddsshDialog inputStr={inputStr} initFormDialogOpen={formDialogOpen} />
      ) : (
        ''
      )}
    </Grid>
  );
}
