import React, { useState, useEffect } from 'react';
import { serverConfig } from '../../config';
import axios from 'axios';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function CV() {
  const [numPages, setNumPages] = useState(null);
  const [uploadConceptData, setUploadConceptData] = useState('');

  useEffect(() => {
    let url = new URL('/api/cv', serverConfig.baseUrl);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setUploadConceptData('data:application/pdf;base64,' + data);
        console.log('data:application/pdf;base64,' + data);
      })
      .catch(function (e) {
        console.log('error: ', e);
      });
  }, []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  return (
    <div className="Example">
      <Document
        file={uploadConceptData}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        renderMode="svg"
      >
        <Page pageNumber={1} scale="1.5" />
      </Document>
    </div>
  );
}
